import { FunctionComponent, useMemo } from 'react';
import { SearchIndexCourseResult } from '../../../../services/search/searchEducator';
import styles from './styles.module.scss';
import { CourseRow } from '../../../CourseRow';

type DatedRowsProps = {
    courses: SearchIndexCourseResult['results'];
    showPricing?: boolean;
    showVATLabel?: boolean;
};

export const DatedRows: FunctionComponent<DatedRowsProps> = ({
    courses,
    showPricing = true,
    showVATLabel,
}) => {
    const coursesOrOccurrences = useMemo(() => {
        return courses
            .flatMap((course) => {
                if (course.occurrences && course.occurrences.length) {
                    return course.occurrences.filter((occurrence) => {
                        if (occurrence.parent_ref) {
                            return occurrence.individually_bookable === true;
                        }
                        return true;
                    });
                }
                return [course];
            })
            .sort((courseOrOccurrence1, courseOrOccurrence2) => {
                return (
                    (courseOrOccurrence1.start_datetime || Infinity) -
                    (courseOrOccurrence2.start_datetime || Infinity)
                );
            });
    }, [courses]);

    return (
        <ul className={styles.courseRows}>
            {coursesOrOccurrences.map((courseOrOccurrence) => {
                return (
                    <li key={courseOrOccurrence._id}>
                        <CourseRow
                            course={courseOrOccurrence}
                            showPricing={showPricing}
                            showVATLabel={showVATLabel}
                        />
                    </li>
                );
            })}
        </ul>
    );
};
