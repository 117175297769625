import { HTMLAttributes, ReactNode, useCallback, KeyboardEvent, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type RadioGroupProps<Type> = {
    options: Array<Type>;
    value?: Type;
    onChange: (newValue: Type) => void;
    comparitor?: (value1: Type, value2: Type) => boolean;
    children: (option: Type, isSelected: boolean, isFocused: boolean) => ReactNode;
} & Omit<HTMLAttributes<HTMLUListElement>, 'children' | 'onChange'>;

const defaultComparitor = (value1: unknown, value2: unknown) => {
    return JSON.stringify(value1) === JSON.stringify(value2);
};

export const RadioGroup = <Type,>({
    options,
    value,
    onChange,
    comparitor = defaultComparitor,
    children,
    className,
    ...ulProps
}: RadioGroupProps<Type>) => {
    const optionRefMap = useRef<{ [key: string]: HTMLLIElement | null }>({});

    const [focusedIndex, setFoccussedIndex] = useState<number>(0);

    const setFoccussedIndexAndFocus = useCallback((index: number) => {
        setFoccussedIndex(index);
        optionRefMap.current[index]?.focus();
    }, []);

    const onListKeyUp = useCallback(
        (event: KeyboardEvent<HTMLUListElement>) => {
            switch (event.key) {
                case ' ': {
                    event.preventDefault();
                    const selectedItem = options[focusedIndex];
                    if (value && comparitor(selectedItem, value) === true) {
                        return;
                    }
                    return onChange(selectedItem);
                }
                case 'ArrowDown':
                case 'ArrowRight': {
                    event.preventDefault();
                    const newIndex = focusedIndex === options.length - 1 ? 0 : focusedIndex + 1;
                    setFoccussedIndexAndFocus(newIndex);
                    const selectedItem = options[newIndex];
                    if (value && comparitor(selectedItem, value) === true) {
                        return;
                    }
                    return onChange(selectedItem);
                }
                case 'ArrowUp':
                case 'ArrowLeft': {
                    event.preventDefault();
                    const newIndex = focusedIndex === 0 ? options.length - 1 : focusedIndex - 1;
                    setFoccussedIndexAndFocus(newIndex);
                    const selectedItem = options[newIndex];
                    if (value && comparitor(selectedItem, value) === true) {
                        return;
                    }
                    return onChange(selectedItem);
                }
            }
        },
        [options, focusedIndex, onChange, setFoccussedIndexAndFocus, comparitor, value]
    );

    const onOptionClick = useCallback(
        (index: number) => {
            return () => {
                setFoccussedIndex(index);
                const selectedItem = options[index];
                if (value && comparitor(selectedItem, value) === true) {
                    return;
                }
                return onChange(selectedItem);
            };
        },
        [value, options, onChange, comparitor]
    );

    return (
        <ul
            role="radiogroup"
            className={classNames(styles.container, className)}
            {...ulProps}
            onKeyUp={onListKeyUp}
        >
            {options.map((option, index) => {
                const isSelected = value !== undefined && comparitor(value, option);
                const isFocussed = focusedIndex === index;
                const tabIndex = isFocussed ? 0 : -1;
                return (
                    <li
                        role="radio"
                        ref={(element) => (optionRefMap.current[index] = element)}
                        key={JSON.stringify(option)}
                        aria-checked={isSelected}
                        tabIndex={tabIndex}
                        onClick={onOptionClick(index)}
                    >
                        {children(option, isSelected, isFocussed)}
                    </li>
                );
            })}
        </ul>
    );
};
